<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Détail contrat"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col md="12">
      <b-card
        title=" contrat"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <h2 class="text-center mb-2">
                {{ contratsSimples.type_contrat.title }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-list-group>
                <b-list-group-item
                  href="#"
                  active
                >
                  Conditions financières
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                >
                  Prix d'achat convenu :  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.prix_achat }}
                  </b-badge>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                >
                  Montant de l'acompte versé :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.montant_acompte }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                >
                  Montant du prêt hypothécaire :

                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.montant_pret_hypothecaire }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Taux d'intérêt :

                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.taux }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Durée du contrat (nombre d'années) :

                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.duree_contrat }}
                  </b-badge>

                </b-list-group-item>

              </b-list-group>
            </b-col>
            <b-col md="4">
              <b-list-group>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                  active
                >  Conditions de paiement

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Échéancier des paiements :

                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.echeancier_paiement }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Montant à payer :

                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.montant_a_payer }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Reste à payer :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.reste_a_payer }}
                  </b-badge>

                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Méthode de paiement acceptée  :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.mode_paiement }}
                  </b-badge>

                </b-list-group-item>

              </b-list-group>
            </b-col>
            <b-col md="4">
              <b-list-group>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                  active
                >
                  Date de début et de fin du contrat
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Date de début du contrat :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.date_debut_contrat }}
                  </b-badge>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Date d'alerte de fin de contrat :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.date_alerte_fin_bail }}
                  </b-badge>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center"
                > Date de fin du contrat :
                  <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                  >
                    {{ contratsSimples.date_cloture_contrat }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  onMounted,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BCard, BButton, BCardBody, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle

// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const modeleEconomiqueSocieteVariant = status => {
      if (status === 'Abonnement') return 'primary'
      if (status === 'Commission') return 'danger'
      return 'primary'
    }

    const {
      getContractAchatVenteById, contratsSimples,

    } = useProprietes()

    onMounted(async () => {
      await getContractAchatVenteById(router.currentRoute.params.id)

      console.log(contratsSimples)
    })

    return {
      contratsSimples,
      modeleEconomiqueSocieteVariant,
      avatarText,
    }
  },

}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
